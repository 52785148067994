import React, { memo } from 'react'
import {
  Box,
  Button,
  IconButton,
  Container,
  Typography,
  styled,
  useTheme,
} from '@mui/material'
import {
  GatsbyImage,
  IGatsbyImageData,
  getImage,
  withArtDirection,
} from 'gatsby-plugin-image'
import { CaretDown } from 'phosphor-react'

// @ts-ignore
import { DatoCmsButton } from '__generated__/gatsby-types'

import BackgroundPlx from 'components/atoms/BackgroundPlx'
import Div100vh from 'components/atoms/Div100vh'
import FixedHeightHeroContainer from 'components/atoms/FixedHeightHeroContainer'
import MainHeading from 'components/molecules/MainHeading'
import CmsLinkButton from 'components/atoms/buttons/CmsLinkButton'

interface BgImageProps {
  fixedHeight?: boolean
}

interface SharedProps extends BgImageProps {
  moreButtonLabel?: string
}

interface ContentProps extends SharedProps {
  keyId: string
  heading: string
  button?: DatoCmsButton
}

interface ImageHeroProps extends ContentProps {
  imageLandscape: IGatsbyImageData
  imagePortrait: IGatsbyImageData
  imageAlt: string
}

interface MoreButtonLayoutProps extends SharedProps {
  children: React.ReactNode
  scrollDown: Function
}

const containerStyles = {
  position: 'relative',
  overflow: 'hidden',
  color: 'white',
}

const FullHeightContainer = styled(Div100vh)(
  // @ts-ignore
  ({ theme }) => ({
    ...containerStyles,
    background: theme.palette.secondary.main,
  })
)

const StyledFixedHeightHeroContainer = styled(FixedHeightHeroContainer)(
  // @ts-ignore
  ({ theme }) => ({
    ...containerStyles,
    background: theme.palette.secondary.main,
  })
)

const BgImage = styled(GatsbyImage, {
  shouldForwardProp: (prop) => prop !== 'fixedHeight',
})<BgImageProps>(({ fixedHeight, theme }) => ({
  opacity: 0.64,
  height: '100%',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    display: 'block',
    height: '0',
    paddingTop: fixedHeight ? '140%' : '220%',
  },
}))

const MoreTextButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  transition: theme.transitions.create(['color']),
  ['&:hover']: {
    color: theme.palette.primary.main,
  },
}))

const MoreIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.white,
  transition: theme.transitions.create(['color']),
  ['&:hover']: {
    color: theme.palette.primary.main,
  },
}))

// The actual content that sits on top of the bg image
const Content = ({
  heading,
  keyId,
  button,
  fixedHeight,
  moreButtonLabel,
}: ContentProps) => {
  return (
    <Box
      className={fixedHeight ? 'fixed-height' : ''}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 1,
        height: 1,
        pt: moreButtonLabel ? 25 : 10,
        pb: moreButtonLabel ? 10 : 10,
      }}
    >
      <Box textAlign={{ xs: 'center' }}>
        <MainHeading
          variant="h1"
          text={heading}
          keyId={keyId}
          breakpoint="xs"
        />
        {button && (
          <CmsLinkButton
            variant="contained"
            button={button}
            size="large"
            sx={{
              mt: 3,
            }}
          />
        )}
      </Box>
    </Box>
  )
}

// The Content layout if a more button is to be used
const MoreButtonLayout = ({
  children,
  fixedHeight,
  moreButtonLabel,
  scrollDown,
}: MoreButtonLayoutProps) => {
  return (
    <Box
      className={fixedHeight ? 'fixed-height' : ''}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100%"
    >
      {children}
      <Box
        height="150px"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <MoreTextButton
          onClick={() => {
            scrollDown()
          }}
        >
          <Typography variant="smallText">{moreButtonLabel}</Typography>
        </MoreTextButton>
        <MoreIconButton
          size="large"
          onClick={() => {
            scrollDown()
          }}
          aria-label="Explore more content"
        >
          <CaretDown size={50} weight="thin" />
        </MoreIconButton>
      </Box>
    </Box>
  )
}

const ImageHero = ({
  keyId,
  imageLandscape,
  imagePortrait,
  imageAlt,
  heading,
  button,
  fixedHeight,
  moreButtonLabel,
}: ImageHeroProps) => {
  const theme = useTheme()
  const moreRef = React.useRef<HTMLDivElement>(null)

  const scrollDown = () => {
    moreRef.current.scrollIntoView({
      behavior: 'smooth',
    })
  }

  const images = withArtDirection(
    // @ts-ignore
    getImage(imageLandscape),
    [
      {
        media: `(max-width: ${theme.breakpoints.values.sm}px)`,
        image: getImage(imagePortrait),
      },
    ]
  )

  const parallaxData = [
    {
      start: 'self',
      end: 'self',
      endOffset: '200vh',
      properties: [
        {
          startValue: 0,
          endValue: 50,
          property: 'translateY',
          unit: 'vh',
        },
      ],
    },
  ]

  const content = (
    <Content
      keyId={keyId}
      heading={heading}
      button={button}
      fixedHeight={fixedHeight}
      moreButtonLabel={moreButtonLabel}
    />
  )

  const contentLayout = (
    <Box bgcolor="common.black" height="100%">
      <BackgroundPlx parallaxData={parallaxData}>
        <BgImage
          image={images}
          alt={imageAlt ? imageAlt : ''}
          fixedHeight={fixedHeight}
        />
      </BackgroundPlx>
      <Container
        maxWidth="lg"
        className={fixedHeight ? 'fixed-height' : ''}
        sx={{ height: '100%', position: 'relative' }}
      >
        {moreButtonLabel ? (
          <MoreButtonLayout
            fixedHeight={fixedHeight}
            moreButtonLabel={moreButtonLabel}
            scrollDown={scrollDown}
          >
            {content}
          </MoreButtonLayout>
        ) : (
          <>{content}</>
        )}
      </Container>
    </Box>
  )

  return (
    <>
      {fixedHeight ? (
        <StyledFixedHeightHeroContainer>
          {contentLayout}
        </StyledFixedHeightHeroContainer>
      ) : (
        <FullHeightContainer>{contentLayout}</FullHeightContainer>
      )}
      {moreButtonLabel && <div ref={moreRef} />}
    </>
  )
}

export default memo(ImageHero)
