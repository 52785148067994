import React, { memo, FC } from 'react'
import { styled } from '@mui/material'
import Div100vhBase from 'react-div-100vh'

const Div = styled(Div100vhBase)({
  height: '1px',
  minHeight: '640px',
})

const Div100vh: FC = (props) => {
  return <Div {...props} />
}

export default memo(Div100vh)
