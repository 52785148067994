import React, { memo, useRef, useEffect } from 'react'
import {
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  styled,
} from '@mui/material'
import { Image } from 'phosphor-react'
import { Field, FieldProps, useFormikContext } from 'formik'

import FieldErrorMessage from './FieldErrorMessage'

interface ImageUploadFieldProps {
  fieldName: string
}

const Input = styled('input')({
  display: 'none',
})

const StyledOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
  paddingLeft: '22px',
  paddingRight: 0,
  ['&:not(.has-file)']: {
    color: 'white',
  },
}))
const StyledButton = styled(Button)(({ theme }) => ({
  borderTopLeftRadius: '0 !important',
  borderBottomLeftRadius: '0 !important',
}))

const ImageUploadField = ({ fieldName }: ImageUploadFieldProps) => {
  const buttonLabelRef = useRef<HTMLSpanElement>(null)
  const { setFieldValue, setFieldTouched, handleBlur, values } =
    useFormikContext()

  const file = values[fieldName]

  const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.currentTarget.files
    if (!fileList) return
    setFieldValue(fieldName, fileList[0])
    setFieldTouched(fieldName)
  }

  let className = ''
  let fileName = ''
  if (file && file.name) {
    fileName = file.name
    className = 'has-file'
  }

  useEffect(() => {
    if (file && file.name) {
      setFieldTouched(fieldName)
    }
    if (buttonLabelRef && buttonLabelRef.current) {
      buttonLabelRef.current.innerText = 'Choose File'
    }
  }, [file])

  return (
    <Field name={fieldName}>
      {({ field, meta }: FieldProps) => {
        const hasError = meta.touched && meta.error !== undefined
        const valid = meta.touched && !meta.error && file && file.name

        if (valid) {
          className += ' valid'
        }

        return (
          <>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor={`${fieldName}-input`}>Image</InputLabel>
              <StyledOutlinedInput
                id={`${fieldName}-input`}
                className={className}
                onBlur={(e) => {
                  // call the built-in handleBur
                  handleBlur(e)
                  setFieldTouched(fieldName)
                }}
                fullWidth
                label="Image"
                error={hasError}
                value={fileName}
                inputProps={{
                  autoComplete: 'off',
                }}
                startAdornment={file ? <Image size={36} /> : null}
                endAdornment={
                  <label htmlFor={`${fieldName}-upload`}>
                    <Input
                      name={fieldName}
                      accept=".jpg, .gif, .png"
                      id={`${fieldName}-upload`}
                      type="file"
                      onChange={handleUpload}
                    />
                    <StyledButton
                      name={`${fieldName} Upload`}
                      id={`${fieldName}-button`}
                      variant="contained"
                      component="span"
                      onBlur={(e) => {
                        // call the built-in handleBur
                        handleBlur(e)
                        setFieldTouched(fieldName)
                      }}
                    >
                      <span ref={buttonLabelRef}>Image</span>
                    </StyledButton>
                  </label>
                }
                sx={{
                  ['&.valid .MuiOutlinedInput-notchedOutline']: {
                    borderColor: 'success.light',
                  },
                }}
              />
            </FormControl>
            <FieldErrorMessage collapseIn={hasError} message={meta.error} />
          </>
        )
      }}
    </Field>
  )
}

export default memo(ImageUploadField)
