import React, { memo } from 'react'
import { Box, Button, Grid, styled } from '@mui/material'
import { Formik, Form as FormikForm, FormikHelpers } from 'formik'
import { string, boolean, mixed, object } from 'yup'

import { useMessage } from 'context/MessageContext'
import encodeForm from 'helpers/encodeForm'
import TextField from 'components/molecules/formFields/TextField'
import Checkbox from 'components/molecules/formFields/Checkbox'
import UploadField from 'components/molecules/formFields/ImageUploadField'

interface ShareStoryFormProps {
  keyId: string
  successMessage: string
  errorMessage: string
  buttonLabel: string
  disclaimer: string
}

interface FormValues {
  botField?: string
  storyTitle: string
  name: string
  email: string
  image: File | undefined
  story: string
  disclaimer: boolean
}

const Input = styled('input')({
  display: 'none',
})

const imageFormats = ['image/jpeg', 'image/png']

const ShareStoryForm = ({
  successMessage,
  errorMessage,
  buttonLabel,
  disclaimer,
}: ShareStoryFormProps) => {
  const { dispatch } = useMessage()
  const formName = 'share-your-story'

  const handleSubmit = (
    values: FormValues,
    actions: FormikHelpers<FormValues>
  ) => {
    fetch('/?no-cache=1', {
      method: 'POST',
      body: encodeForm({ 'form-name': formName, ...values }),
    })
      .then(() => {
        dispatch({
          type: 'setMessage',
          messageStatus: 'success',
          message: successMessage,
        })
        actions.resetForm()
      })
      .catch(() => {
        dispatch({
          type: 'setMessage',
          messageStatus: 'error',
          message: errorMessage,
        })
      })
      .finally(() => actions.setSubmitting(false))
  }

  const gridItemWidths = {
    xs: 12,
    md: 6,
  }

  return (
    <Formik
      initialValues={{
        botField: '',
        storyTitle: '',
        name: '',
        email: '',
        image: undefined,
        story: '',
        disclaimer: false,
      }}
      validationSchema={object().shape({
        storyTitle: string().required('Please enter a title for your story'),
        name: string().required('Please enter your name'),
        email: string()
          .email('Please supply a valid email address')
          .required('Email address is Required'),
        image: mixed()
          .test(
            'fileFormat',
            'Your Image must be a either jpg, gif or png file.',
            (value) => {
              if (value === undefined || !value.length) {
                return true
              }
              return value && imageFormats.includes(value.type)
            }
          )
          .test(
            'fileSize',
            'Your image file is too large. Please upload a versions that is less than 4mb.',
            (value) => {
              if (value === undefined || !value.length) {
                return true
              }
              return value && value.size && value.size <= 4194304 // 4mb
            }
          ),
        story: string().required('Please add your story'),
        disclaimer: boolean().oneOf([true], 'Please accept our disclaimer'),
      })}
      onSubmit={handleSubmit}
    >
      <FormikForm
        name={formName}
        data-netlify="true"
        data-netlify-honeypot="botField"
        noValidate
      >
        <Box display="none">
          <TextField
            name="botField"
            label="Name"
            textFieldProps={{
              id: 'bot-field',
              type: 'hidden',
            }}
          />
          <input type="hidden" name="form-name" value={formName} />
        </Box>
        <Grid container spacing={3}>
          <Grid item {...gridItemWidths}>
            <TextField
              name="storyTitle"
              label="Story Title"
              textFieldProps={{
                id: 'story-title',
                fullWidth: true,
                required: true,
              }}
            />
          </Grid>
          <Grid item {...gridItemWidths}>
            <TextField
              name="name"
              label="Name"
              textFieldProps={{
                id: 'name',
                fullWidth: true,
                required: true,
              }}
            />
          </Grid>
          <Grid item {...gridItemWidths}>
            <TextField
              name="email"
              label="Email Address"
              textFieldProps={{
                id: 'email',
                fullWidth: true,
                required: true,
              }}
            />
          </Grid>
          <Grid item {...gridItemWidths}>
            <UploadField fieldName="image" />
          </Grid>
          <Grid item xs={12} sx={{ mb: { xs: 2, lg: 4 } }}>
            <TextField
              name="story"
              label="Your Story"
              textFieldProps={{
                id: 'story',
                fullWidth: true,
                required: true,
                multiline: true,
                rows: 8,
              }}
            />
          </Grid>
          <Grid item xs={12} md={9}>
            <Checkbox label={disclaimer} name="disclaimer" />
          </Grid>
          <Grid item xs={12} md={3}>
            <Button
              variant="contained"
              type="submit"
              fullWidth
              sx={{ mt: '9px' }}
            >
              {buttonLabel}
            </Button>
          </Grid>
        </Grid>
      </FormikForm>
    </Formik>
  )
}

export default memo(ShareStoryForm)
