import React from 'react'
import { graphql } from 'gatsby'
import { Container, Typography } from '@mui/material'

// @ts-ignore
import { ShareStoryQuery } from '__generated__/gatsby-types'

import Layout from 'components/organisms/global/Layout'
import ImageHero from 'components/organisms/hero/ImageHero'
import CurveMask from 'components/atoms/CurveMask'
import ContentComponents from 'components/molecules/contentComponents/ContentComponents'
import ShareStoryForm from 'components/organisms/forms/ShareStoryForm'

interface Props {
  data: ShareStoryQuery
}

const ShareStoryPage = ({ data }: Props) => {
  const d = data.datoCmsShareStoryPage

  return (
    <Layout
      metadata={{
        seoTags: d.seoMetaTags,
      }}
    >
      <ImageHero
        keyId={d.hero[0].originalId}
        imageLandscape={d.hero[0].imageLandscape.gatsbyImageData}
        imagePortrait={d.hero[0].imagePortrait.gatsbyImageData}
        heading={d.hero[0].mainHeadingNode.childMarkdownRemark.html}
        fixedHeight
      />
      <CurveMask
        upShift
        bgColor="common.white"
        containerBoxProps={{
          component: 'section',
        }}
        contentBoxProps={{
          pt: {
            xs: 6,
            sm: 7,
            md: 8,
            lg: 9,
            xl: 10,
          },
        }}
        sunProps={{
          size: 'large',
          position: 'top-left',
          hideBelow: { breakpoint: 'md' },
        }}
      >
        <ContentComponents
          boxProps={{ pb: { xs: 0, md: 8, lg: 10, xl: 13 } }}
          components={d.contentComponents}
          containedWidth="wide"
          textImageFullSection
        />
      </CurveMask>
      <CurveMask
        bgColor="lightGrey"
        containerBoxProps={{
          bgcolor: { xs: 'primary.main', md: 'white' },
        }}
        contentBoxProps={{
          pt: { xs: 5 },
        }}
        sunProps={{
          size: 'large',
          position: 'top-right',
          hideBelow: { breakpoint: 'md' },
        }}
      >
        <Container maxWidth="xl" sx={{ textAlign: 'center' }}>
          <Typography variant="h2" sx={{ mb: 4 }}>
            {d.formHeading}
          </Typography>
        </Container>
        <Container maxWidth="md" sx={{ textAlign: 'center' }}>
          <Typography variant="body1" sx={{ mb: 4 }}>
            {d.formText}
          </Typography>
        </Container>
        <Container maxWidth="lg" sx={{ textAlign: 'center', pb: 10 }}>
          <ShareStoryForm
            buttonLabel={d.submissionButtonLabel}
            disclaimer={d.disclaimerText}
            successMessage={d.successMessage}
            errorMessage={d.errorMessage}
          />
        </Container>
      </CurveMask>
    </Layout>
  )
}

export default ShareStoryPage

export const pageQuery = graphql`
  query ShareStoryQuery {
    datoCmsShareStoryPage {
      originalId
      slug
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      hero {
        originalId
        smallHeading
        mainHeadingNode {
          childMarkdownRemark {
            html
          }
        }
        imageLandscape: heroImage {
          gatsbyImageData(
            width: 1600
            layout: FULL_WIDTH
            imgixParams: { ar: "4:3", fit: "crop", crop: "focalpoint" }
          )
        }
        imagePortrait: heroImage {
          gatsbyImageData(
            width: 900
            layout: FULL_WIDTH
            imgixParams: { ar: "1:1.4", fit: "crop", crop: "focalpoint" }
          )
        }
      }
      contentComponents {
        ... on DatoCmsLeadText {
          ...contentLeadText
        }
        ... on DatoCmsImageLeadTextSection {
          ...contentImageLeadTextSection
        }
      }
      formHeading
      formText
      disclaimerText
      errorMessage
      successMessage
      submissionButtonLabel
    }
  }
`
